import React, { useState, useEffect } from 'react';
import './App.css';

const Next7Days = () => {
  const [next7Games, setNext7Games] = useState([]);

  useEffect(() => {
    const fetchGameData = async () => {
      const response = await fetch('/schedule.json');
      const data = await response.json();
      findNext7Games(data);
    };

    fetchGameData();
  }, []);

  const findNext7Games = (games) => {
    const today = new Date();
    const dates = [...Array(7)].map((_, i) => {
      const nextDay = new Date(today.getFullYear(), today.getMonth(), today.getDate() + i);
      return nextDay;
    });
  
    const next7Games = dates.map(date => {
      const game = games.find(game => {
        const gameDate = new Date(game.date);
        return gameDate.toDateString() === date.toDateString();
      });
      // Ensure that game.date is a Date object
      return game ? { ...game, date: new Date(game.date) } : { date: date, off: true };
    });
  
    setNext7Games(next7Games);
  };

  const formatDate = (date) => {
    const dayOfWeek = date.toLocaleString('en-US', { weekday: 'short' }); // e.g., "Mon"
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
    return `${dayOfWeek} ${month}/${day}`;
  };
  

  return (
    <div className="next-7-days">
      {next7Games.map((game, index) => {
        // Convert game.date to a Date object if necessary and format it
        const gameDate = game.date instanceof Date ? game.date : new Date(game.date);
        const formattedDate = formatDate(gameDate);
        return (
          <div key={index} className={`game-cell ${game.off ? "off" : game.location.includes("Wrigley Field") ? "home" : "away"}`}>
            {game.off ? (
              <>
                {formattedDate}
                <br />
                Off Day
              </>
            ) : (
              <>
                {formattedDate}
                <br />
                {game.location.includes("Wrigley Field") ? `vs ${game.match.split(' at ')[0]}` : `@${game.match.split(' at ')[1]}`}
                <br />
                <br />
                {game.time}
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Next7Days;