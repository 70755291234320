import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './App.css';

const GameInfo = () => {
  const [gameData, setGameData] = useState(null);

  useEffect(() => {
    const fetchGameData = async () => {
      const response = await fetch('/schedule.json');
      const data = await response.json();
      setGameData(data);
    };

    fetchGameData();
  }, []);

  const findTodaysGame = () => {
    const today = new Date();

    // Absolutely brutal logic generated by ChatGPT to compensate for 
    // a terrible date format it also generated! But 🤷‍♂️.
    const dateString = (
      (today.getMonth() + 1) + '').padStart(2, '0') + '/' +  
      (today.getDate() + '').padStart(2, '0') + '/' + today.getFullYear().toString().slice(-2);

    return gameData?.find(game => game.date === dateString) || null;
  };

  const todaysGame = findTodaysGame();
  return (
    <>
      <div className="game-info">
        {todaysGame ? (
          <div>
            <div>
              <p>The Cubs play today &</p>
              <p>It's {todaysGame.match} &</p>
              <p>It's {(todaysGame.location.includes("Wrigley Field") || todaysGame.location.includes("Sloan Park")) ? "a home" : "an away"} game</p>
            </div>
            <div className="follow-up">
              <p>
                {todaysGame.delayedStart ? (
                  <>
                    <s>{todaysGame.time}</s> {todaysGame.delayedStart}
                  </>
                ) : (
                  todaysGame.time
                )}
                &nbsp;CT
              </p>
            </div>
          </div>
        ) : (
          <>
            <div>
              <p>The Cubs don't play today &</p>
              <p>That's a bummer &</p>
              <p>It's going to be ok</p>
            </div>
            <div className="follow-up">
              <p>
                They play <Link className="link" to="/next">soon</Link>
              </p>
            </div>
          </>
        )}
      </div>
      <div className="emoji-corner">
        🐻❤️
      </div>
    </>
  );
};

export default GameInfo;
