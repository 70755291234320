import React, { useState, useEffect } from 'react';
import './App.css';

const NextGame = () => {
  const [nextGame, setNextGame] = useState(null);

  useEffect(() => {
    const fetchGameData = async () => {
      const response = await fetch('/schedule.json');
      const data = await response.json();
      findNextGame(data);
    };

    fetchGameData();
  }, []);

  const findNextGame = (games) => {
    const today = new Date();
    const upcomingGame = games.find(game => new Date(game.date) > today);
    setNextGame(upcomingGame);
  };

  return (
    <>
      <div className="game-info">
        {nextGame ? (
          <>
            <div>
              <p>The Cubs play on {nextGame.date} &</p>
              <p>It's {nextGame.match} &</p>
              <p>It's {nextGame.location.includes("Wrigley Field") ? "a home" : "an away"} game</p>
            </div>
            <div className="follow-up">
              <p>It starts at {nextGame.time} CT</p>
            </div>
          </>
        ) : (
          <p>No upcoming games</p>
        )}
      </div>
      <div className="emoji-corner">
        🐻❤️
      </div>
    </>
  );
};

export default NextGame;
